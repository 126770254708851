<script setup>
import { inject, onBeforeMount } from 'vue'
const IS_APP_DYNAMIC = inject('IS_APP_DYNAMIC')
import { useRouter } from 'vue-router'
const router = useRouter()
// Redirect to dynamic site if app is dynamic view and to static home url outside of vue if not
onBeforeMount(() => {
  if (IS_APP_DYNAMIC) {
    return router.push({ name: 'dynamicSite' })
  } else {
    window.location.href = '/'
  }
})
</script>
<template>&nbsp;</template>
