<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import { onBeforeMount, inject } from 'vue'
import Template1 from '@/layouts/dynamicSite/template1/Template1.vue'
import Template2 from '@/layouts/dynamicSite/template2/Template2.vue'
import Template3 from '@/layouts/dynamicSite/template3/Template3.vue'
import Template4 from '@/layouts/dynamicSite/template4/Template4.vue'

let dynamicSiteTemplate = getDynamicSiteTemplate()

function getDynamicSiteTemplate() {
  const restoStore = useRestoGLobalStore()
  // TODO : get template from API here
  const template = restoStore?.restoInfo?.dynamic_site_content?.template
  const templateMap = {
    1: Template1,
    2: Template2,
    3: Template3,
    4: Template4
  }
  return templateMap[template] || Template1
}
const restoStore = useRestoGLobalStore()
const IS_APP_DYNAMIC = inject('IS_APP_DYNAMIC')
const router = useRouter()
onBeforeMount(() => {
  if (!IS_APP_DYNAMIC) {
    return router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
  }
})
</script>
<template><component :is="dynamicSiteTemplate"></component></template>
