<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount } from 'vue'
import WOW from 'wow.js'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import ContactView from '@/views/contact/ContactView.vue'
const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content

const address = `${dynamicContent?.info?.address}`
const mapSrc = `https://maps.google.com/maps?&q="+${address}"&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')

const handleScroll = () => {
  isSticky.value = window.scrollY > 100
}
const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}
onBeforeMount(() => {
  if (dynamicContent?.style == 1) {
    import('@/layouts/dynamicSite/template3/css/style1.css')
    bgColor1.value = '#F28123'
  } else if (dynamicContent?.style == 2) {
    import('@/layouts/dynamicSite/template3/css/style2.css')
    bgColor1.value = '#FEA116'
  } else if (dynamicContent?.style == 3) {
    import('@/layouts/dynamicSite/template3/css/style3.css')
    bgColor1.value = '#40ba37'
  } else {
    import('@/layouts/dynamicSite/template3/css/style4.css')
    bgColor1.value = '#fac564'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  new WOW().init()
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div class="temp-w3">
    <!-- ====== Header section start here ====== -->
    <div class="top-strip flex" v-if="dynamicContent?.content?.rsc_resto_note_customer_message">
      <span class="mr-2">Notice:</span>
      <marquee onmouseover="this.stop()" onmouseout="this.start()">
        {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
      </marquee>
    </div>

    <header
      :class="{ 'sticky-header': isSticky }"
      class="text-white md:py-4 py-3 md:px-4 px-4 z-50"
    >
      <div class="container mx-auto flex items-center justify-between w-full">
        <!-- Navigation Links -->
        <nav
          class="hidden md:flex lg:space-x-4 md:space-x-1 space-x-2 justify-center items-center w-full"
        >
          <a href="#home" class="uppercase lg:px-4 px-2 py-2 font-semibold">Home</a>
          <a href="#aboutUs" class="uppercase lg:px-4 px-2 py-2 font-semibold">About Us</a>
          <router-link
            class="uppercase lg:px-4 px-2 py-2 font-semibold"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <div
            class="flex items-center justify-center lg:w-1/5 w-1/6 ease-in-out wow zoomIn general_grow animated"
          >
            <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-16 w-16" />
          </div>
          <a href="#gallery" class="uppercase lg:px-4 px-2 py-2 font-semibold">Gallery</a>
          <a href="#contact" class="uppercase lg:px-4 px-2 py-2 font-semibold">Contact</a>
          <router-link
            class="btn-filled lg:px-4 px-2 py-2 lg:text-xl text-lg font-semibold border-2"
            :class="[dynamicContent?.info?.is_reservation_active ? 'visible' : 'invisible']"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </nav>

        <!-- Mobile Menu Button -->
        <div class="md:hidden w-1/5">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12" />
        </div>
        <button class="md:hidden focus:outline-none" @click="handleShowHeader()">
          <svg
            class="h-6 w-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu bg-gray-600 absolute left-0 right-0 top-16 md:hidden pb-4"
        >
          <a href="#home" class="block uppercase py-2 px-4">Home</a>
          <a href="#aboutUs" class="block uppercase py-2 px-4">About Us</a>
          <router-link
            class="block uppercase py-2 px-4"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="block uppercase py-2 px-4">Gallery</a>
          <a href="#contact" class="block uppercase py-2 px-4">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn-filled my-2 mx-4 mt-2 px-4 py-1 text-lg font-semibold border-2 transition ease-in-out wow zoomIn general_grow animated"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </div>
      </div>
    </header>
    <!-- ====== Header section End ====== -->

    <!-- ====== Images slider start here ===== -->
    <section id="home" class="carousel slide top_slider" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div
            class="slider-item slider_1 bg-cover bg-center h-screen"
            :style="{
              background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${dynamicContent?.content?.rsc_slider_background_image_1_link})`
            }"
          ></div>
        </div>
      </div>

      <div class="container">
        <div
          class="flex justify-center items-center absolute md:top-16 top-4 bottom-0 mx-auto w-full"
        >
          <div class="content-wrapper text-center font-sans">
            <!-- <p class="subtitle md:text-xl text-xl italic">Welcome to </p> -->
            <h1
              class="mb-5 sm:text-4xl md:text-8xl text-3xl font-bold antialiased text-white wow zoomIn general_grow animated"
            >
              {{ dynamicContent?.info?.name }}
            </h1>

            <div
              v-if="dynamicContent?.info?.active_website_offers"
              class="mb-5 lg:mb-6 md:text-xl text-base font-semibold text-white opacity-90 wow bounceInDown general_down animated"
            >
              <h2
                v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
                :key="index"
                class="text-lg lg:text-xl mb-1"
              >
                <span class="md:text-3xl text-xl opacity-1 relative discount-text">{{
                  offer
                }}</span>
              </h2>
            </div>
            <div class="btn-wrapper mt-5">
              <router-link
                class="btn mr-4 px-4 py-2 text-xl font-semibold border-2 transition ease-in-out wow zoomIn general_grow animated"
                :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
                >Order Now</router-link
              >
              <router-link
                v-if="dynamicContent?.info?.is_reservation_active"
                class="btn mr-4 px-4 py-2 text-xl font-semibold border-2 transition ease-in-out wow zoomIn general_grow animated"
                :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
                >Book Table</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-separate"></div>
    </section>
    <div class="divider flex justify-center md:py-8 py-4">
      <span class="divider-separator"> </span>
    </div>
    <!-- ====== Images slider End ===== -->

    <!-- ====== Aboutus section start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_1"
      id="aboutUs"
      class="container-fluid md:pb-12 pb-4 about-section"
    >
      <div class="container mx-auto">
        <div class="about-content relative md:mx-0 mx-3">
          <div class="flex justify-center flex-wrap">
            <div
              v-if="dynamicContent?.content?.rsc_about_us_image_1_link"
              class="md:mb-0 mb-4 w-2/4 px-2 wow zoomIn general_grow animated"
            >
              <img
                class="md:w-full object-cover"
                :src="dynamicContent?.content?.rsc_about_us_image_1_link"
                alt="About Image 1"
              />
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_about_us_image_2_link"
              class="md:mb-0 mb-4 w-2/4 px-2 wow zoomIn general_grow animated"
            >
              <img
                class="md:w-full object-cover"
                :src="dynamicContent?.content?.rsc_about_us_image_2_link"
                alt="About Image 2"
              />
            </div>
          </div>
          <div
            class="text-black lg:px-8 px-6 lg:py-8 py-6 text-center content max-w-6xl mx-auto wow zoomIn general_grow animated"
          >
            <h2
              class="md:text-6xl text-4xl font-bold md:mb-8 mb-4 section-title relative md:pb-8 pb-4"
            >
              About Our Restaurant
            </h2>
            <p class="text-xl font-semibold">
              {{ dynamicContent?.content?.rsc_about_us_text_1 }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="divider flex justify-center md:py-8 py-4">
      <span class="divider-separator"> </span>
    </div>
    <!-- ====== Aboutus section End ===== -->

    <!-- About us-2 Content -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_2"
      class="container-fluid py-20 about2-section bg-gray-100 wow zoomIn general_grow animated"
    >
      <div class="container mx-auto">
        <div class="text-black lg:px-8 px-6 lg:py-8 py-6 text-center content max-w-6xl mx-auto">
          <p class="mb-4 lg:text-lg text-lg">
            {{ dynamicContent?.content?.rsc_about_us_text_2 }}
          </p>
        </div>
      </div>
    </section>

    <!-- ====== OUR GALLERY section start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
      id="gallery"
      class="container-fluid mx-auto md:py-20 py-12 gallery-section"
    >
      <h2
        class="md:text-6xl text-4xl font-bold md:mb-8 mb-4 section-title relative md:pb-8 pb-4 text-center wow zoomIn general_grow animated"
      >
        Gallery
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:mx-0 mx-3 gap-4">
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_1_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_1_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_2_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_2_link}`"
            alt="Gallery Image 2"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_2_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_3_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_3_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_3_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_4_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_4_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_4_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_5_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_5_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_5_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_6_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_6_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_6_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_7_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_7_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_7_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_8_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_8_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize text-xl">
              {{ dynamicContent?.content?.rsc_gallery_image_8_text }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="divider flex justify-center md:py-8 py-4">
      <span class="divider-separator"> </span>
    </div>
    <!-- ====== OUR GALLERY section End ===== -->

    <!-- ====== OUR video section start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_vgallery_link_1"
      id="videos"
      class="container-fluid mx-auto wow zoomIn general_grow animated"
    >
      <div class="container mx-auto relative">
        <h2
          class="md:text-6xl text-4xl font-bold md:mb-8 mb-8 section-title relative md:pb-8 pb-4 text-center"
        >
          Our Videos
        </h2>
        <div class="flex justify-center flex-wrap">
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_1"
            class="relative overflow-hidden w-2/4 px-2 mb-4"
          >
            <iframe width="100%" height="415" :src="dynamicContent?.content?.rsc_vgallery_link_1">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_2"
            class="relative overflow-hidden w-2/4 px-2 mb-4"
          >
            <iframe width="100%" height="415" :src="dynamicContent?.content?.rsc_vgallery_link_2">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_3"
            class="relative overflow-hidden w-2/4 px-2 mb-4"
          >
            <iframe width="100%" height="415" :src="dynamicContent?.content?.rsc_vgallery_link_3">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_4"
            class="relative overflow-hidden w-2/4 px-2 mb-4"
          >
            <iframe width="100%" height="415" :src="dynamicContent?.content?.rsc_vgallery_link_4">
            </iframe>
          </div>
        </div>
      </div>
    </section>
    <div
      v-if="dynamicContent?.content?.rsc_vgallery_link_1"
      class="divider flex justify-center md:py-8 py-4"
    >
      <span class="divider-separator"> </span>
    </div>
    <!-- ====== OUR video section End ===== -->

    <!-- ====== CONTACT US section start ===== -->
    <section id="contact" class="bg-gray-100 container-fluid mx-auto md:py-20 py-12">
      <div class="container mx-auto">
        <h2 class="md:text-6xl text-4xl font-bold mb-8 section-title relative pb-8 text-center">
          Contact Us
        </h2>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-2/3 p-4 wow zoomIn general_grow animated">
            <ContactView :bgcolor="bgColor1"></ContactView>
          </div>

          <div class="lg:block lg:w-1/3 w-full p-4 wow zoomIn general_grow animated">
            <iframe
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              :src="mapSrc"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- ====== OUR GALLERY section End ===== -->

    <section
      v-if="dynamicContent?.content?.rsc_food_hygiene_rating > 3"
      id="allergyInfo"
      class="container-fluid rating_section py-12"
    >
      <div class="container mx-auto">
        <div class="text-center mx-auto wow zoomIn general_grow animated">
          <div>
            <img
              v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
              src="./../common/images/food-rating-3.svg"
              class="mb-4 inline-block w-1/3"
            />
            <img
              v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
              src="./../common/images/food-rating-4.svg"
              class="mb-4 inline-block w-1/3"
            />
            <img
              v-else
              src="./../common/images/food-rating-5.svg"
              class="mb-4 inline-block w-1/3"
            />
          </div>
          <div v-if="dynamicContent?.content?.rsc_allergy_text_1" class="">
            <p class="font-semibold text-xl">
              {{ dynamicContent?.content?.rsc_allergy_text_1 }}
            </p>
            <p class="font-semibold text-xl">{{ dynamicContent?.content?.rsc_allergy_text_2 }}.</p>
            <a
              class="font-semibold text-lg btn-filled px-4 py-1 border-2 mt-5 inline-block"
              href="https://ratings.food.gov.uk/"
              target="_blank"
              >For more details</a
            >
          </div>
        </div>
      </div>
    </section>

    <!-- ====== FOOTER Start ===== -->
    <footer class="text-white md:pt-18 pt-16 pb-4 px-3 top-separate">
      <div class="container mx-auto">
        <div class="md:grid md:grid-cols-3 gap-8">
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <img
                :src="dynamicContent?.info?.logo"
                alt="Company logo"
                class="rounded-md shadow-md mb-4"
              />
              <div class="flex space-x-4">
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_facebook !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_facebook"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_instagram !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_instagram"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_twitter !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_twitter"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-twitter-square" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <h3 class="text-2xl font-bold mb-2">Find Us</h3>
              <div class="flex flex-col space-y-2">
                <div class="flex items-center">
                  <i class="fa fa-map-marker mr-2"></i>
                  <span class="">
                    {{ dynamicContent?.info?.address }}
                  </span>
                </div>
                <div class="flex items-center">
                  <i class="fa fa-phone mr-2"></i>
                  <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="">{{
                    dynamicContent?.info?.phone_number
                  }}</a>
                </div>
                <!-- <div class="flex items-center">
                <i class="fa fa-envelope mr-2 "></i>
                <a href="mailto:bookings@gmail.com" class="">bookings@gmail.com</a>
              </div> -->
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="flex">
              <i class="fa fa-clock-o mr-2 mt-2 text-2xl"></i>
              <h3 class="text-2xl font-bold mb-2">Opening Hours</h3>
            </div>
            <ul class="list-disc ml-4 mb-0">
              <li
                v-for="openTime in dynamicContent?.info?.opening_times"
                :key="openTime?.day_number"
                class="font-bold"
              >
                {{ openTime.day_title }}:
                <span
                  class="font-normal italic"
                  v-for="(time, index) in openTime.times"
                  :key="index"
                  >{{ time }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="border-gray-700" />
      <div class="container mx-auto py-4">
        <div class="text-center wow zoomIn general_grow animated">
          <p class="mb-0">&copy; {{ currentYear }} - All rights reserved</p>
        </div>
      </div>
    </footer>
    <a
      id="backTop"
      href="#"
      class="fixed bottom-4 right-4 rounded-full p-2 shadow-md hover:animate-bounce btn-filled"
    >
      <i class="fa fa-chevron-up" aria-hidden="true"></i>
    </a>
    <!-- ====== FOOTER End ===== -->
  </div>
</template>

<style scoped>
@import 'https://fonts.googleapis.com/css?family=Cormorant+Garamond:300,300i,400,400i,500,600i,700';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@/layouts/dynamicSite/template3/css/responsive.css';
@import '@/layouts/dynamicSite/common/css/animate.css';
</style>
