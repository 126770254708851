<script setup>
import { ref } from 'vue'
import router from '@/router'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useAppNavigation } from '@/composables/AppNavigation'
const restoStore = useRestoGLobalStore()
const { getNavLinks } = useAppNavigation()
let open = ref(false)
</script>

<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <header
    :class="[
      open ? 'sticky top-0 z-20 overflow-y-auto ' : '',
      'sticky top-0 w-full z-20 mx-auto mb-0 border-b bg-white shadow-sm lg:overflow-y-visible'
    ]"
  >
    <div class="min-h-full">
      <Disclosure as="nav" class="border-b border-gray-200 bg-white" v-slot="{ open }">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex h-16 justify-between">
            <div class="flex">
              <div class="flex flex-shrink-0 items-center">
                <RouterLink :to="{ name: 'restoHome' }">
                  <img
                    class="block h-8 w-auto lg:hidden"
                    :src="restoStore.restoInfo.logo"
                    :alt="restoStore.restoInfo.name"
                  />
                  <img
                    class="hidden h-8 w-auto lg:block"
                    :src="restoStore.restoInfo.logo"
                    :alt="restoStore.restoInfo.name"
                  />
                </RouterLink>
              </div>
              <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                <RouterLink
                  v-for="item in getNavLinks()"
                  :key="item.name"
                  :to="{ name: item.route_name, params: item.params, query: item.query }"
                  :class="[
                    router.currentRoute.value.name === item.route_name
                      ? 'border-indigo-500 text-gray-900'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                  ]"
                  :aria-current="
                    router.currentRoute.value.name === item.route_name ? 'page' : undefined
                  "
                  >{{ item.name }}</RouterLink
                >
              </div>
            </div>
            <div class="hidden sm:ml-6 sm:flex sm:items-center">
              <!-- <button
              type="button"
              class="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

              <!-- Profile dropdown -->
              <Menu as="div" class="relative ml-3">
                <div>
                  <MenuButton
                    class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span class="absolute -inset-1.5"></span>
                    <span class="sr-only">Open menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="{1.5}"
                      stroke="currentColor"
                      className="w-6 h-6"
                      class="h-8 w-8 text-gray-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <MenuItem v-for="item in getNavLinks()" :key="item.name" v-slot="{ active }">
                      <RouterLink
                        :to="{ name: item.route_name, params: item.params, query: item.query }"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700'
                        ]"
                        >{{ item.name }}</RouterLink
                      >
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
            <div class="-mr-2 flex items-center sm:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton
                class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span class="absolute -inset-0.5"></span>
                <span class="sr-only">Open main menu</span>
                <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>

        <DisclosurePanel class="sm:hidden">
          <div class="space-y-1 pb-3 pt-2">
            <DisclosureButton
              v-for="item in getNavLinks()"
              :key="item.name"
              as="a"
              @click="
                router.push({ name: item.route_name, params: item.params, query: item.query })
              "
              :class="[
                router.currentRoute.value.name === item.route_name
                  ? 'border-indigo-500 bg-indigo-50 text-indigo-700'
                  : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
                'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
              ]"
              :aria-current="
                router.currentRoute.value.name === item.route_name ? 'page' : undefined
              "
              >{{ item.name }}</DisclosureButton
            >
          </div>
          <!-- <div class="border-t border-gray-200 pb-3 pt-4">
          <div class="flex items-center px-4">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user.imageUrl" alt="" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-gray-800">{{ user.name }}</div>
              <div class="text-sm font-medium text-gray-500">{{ user.email }}</div>
            </div>
            <button
              type="button"
              class="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="absolute -inset-1.5"></span>
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-3 space-y-1">
            <DisclosureButton
              v-for="item in getUserNaviation()"
              :key="item.name"
              as="a"
              @click="router.push({ name: item.route_name })"
              class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
              >{{ item.name }}</DisclosureButton
            >
          </div>
        </div> -->
        </DisclosurePanel>
      </Disclosure>
    </div>
  </header>
</template>
