import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Notifications from 'notiwind'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://56386e93cee69a75a7fed8e00bbd861c@o4507588414799872.ingest.de.sentry.io/4507588416962640',
  environment: import.meta.env.MODE || 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    Sentry.captureConsoleIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // disable distributed tracing for localhost and enable it for your API
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(Notifications)
app.use(pinia)

const authGlobalStore = useAuthGlobalStore()
const restoGlobalStore = useRestoGLobalStore()

router.beforeEach(async (to, from) => {
  // set resto id globally
  if (!restoGlobalStore.RESTO_ID) {
    await restoGlobalStore.handleCheckRestoId()
    // if resto id is not set, redirect to error page
    if (!restoGlobalStore.RESTO_ID && to.name !== 'error') {
      return {
        name: 'error',
        // save the location we were at to come back later
        query: { redirect: to.fullPath }
      }
    }
  }

  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)

  if (to.meta.requiresAuth) {
    if (!authGlobalStore.isLoggedIn) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      return {
        name: 'login',
        // save the location we were at to come back later
        query: { redirect: to.fullPath }
      }
    }
    try {
      const user_data = await authGlobalStore.getAuthUser()
      authGlobalStore.setAuthUser(user_data)
    } catch (error) {
      authGlobalStore.logout().then(() => {
        router.push({ name: 'login', query: { redirect: to.fullPath } })
      })
    }
  }
  document.title = to.meta?.title || restoGlobalStore?.restoInfo?.name
})

app.provide('IS_APP_DYNAMIC', import.meta.env.VITE_APP_DYNAMIC)
app.use(router)
// app.mount('#app')
// wait until router is ready before mounting to ensure hydration match
// otherwise test will fail to use store
router.isReady().then(() => {
  app.mount('#app')
})
