import axios from 'axios'

const restApi = axios.create({
  baseURL: get_api_base_url(),
  withCredentials: true
})

function get_api_base_url() {
  // return api base url
  let api_base_url = ''
  if (import.meta.env.VITE_API_BASE_URL) {
    api_base_url = import.meta.env.VITE_API_BASE_URL
  } else {
    api_base_url =
      location.protocol +
      '//' +
      location.host +
      (import.meta.env.VITE_API_DEV_PORT && import.meta.env.VITE_API_DEV_PORT != 80
        ? ':' + import.meta.env.VITE_API_DEV_PORT
        : '') +
      import.meta.env.VITE_API_URL_PREFIX
  }
  return api_base_url
}

restApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response = error?.response
    if (error.code === 'ERR_NETWORK') {
      console.log('connection problems..')
    } else if (error.code === 'ERR_CANCELED') {
      console.log('connection canceled..')
    }
    if (response) {
      const statusCode = response?.status
      if (statusCode === 404) {
        console.log('The requested resource does not exist or has been deleted')
      } else if (statusCode === 401) {
        console.log('Please login to access this resource')
      }
    }
    //return Promise.reject(error)
    return Promise.resolve(error.response)
  }
)

export default restApi
