<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount } from 'vue'
import WOW from 'wow.js'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import ContactView from '@/views/contact/ContactView.vue'

const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content
const address = `${dynamicContent?.info?.address}`
const mapSrc = `https://maps.google.com/maps?&q="+${address}"&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')

const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}
const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}
onBeforeMount(() => {
  if (dynamicContent?.style == 1) {
    import('@/layouts/dynamicSite/template1/css/style1.css')
    bgColor1.value = '#991b1b'
  } else if (dynamicContent?.style == 2) {
    import('@/layouts/dynamicSite/template1/css/style2.css')
    bgColor1.value = '#fbaf32'
  } else if (dynamicContent?.style == 3) {
    import('@/layouts/dynamicSite/template1/css/style3.css')
    bgColor1.value = '#49a003'
  } else {
    import('@/layouts/dynamicSite/template1/css/style4.css')
    bgColor1.value = '#cda45e'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  new WOW().init()
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div class="temp-w1">
    <!-- Offer text -->
    <div
      v-if="dynamicContent?.content?.rsc_resto_note_customer_message"
      class="container-fluid top-notice pt-1 px-3"
    >
      <div class="top-notice-bar">
        <div class="flex">
          <div class="flex-none w-1/6 md:w-1/12 lg:w-1/12 xl:w-1/12 text-white font-semibold">
            Notice!
          </div>
          <div class="flex-grow w-5/6 md:w-11/12 lg:w-11/12 xl:w-11/12 text-white">
            <marquee
              class="hover:cursor-pointer"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
            </marquee>
          </div>
        </div>
      </div>
    </div>
    <!-- Header -->
    <header
      :class="{ 'sticky-header': isSticky }"
      class="text-white py-4 px-4 sticky top-0 z-50 relative"
    >
      <div class="container mx-auto flex items-center justify-between">
        <!-- Logo -->
        <div class="flex items-center">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
        </div>

        <!-- Navigation Links -->
        <nav class="hidden md:flex items-center space-x-8">
          <a href="#home" class="uppercase">Home</a>
          <a href="#aboutUs" class="uppercase">About Us</a>
          <router-link
            class="uppercase"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="uppercase">Gallery</a>
          <a href="#contact" class="uppercase">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn mr-2 px-5 py-1 rounded-sm text-white md:text-xl text-base"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </nav>

        <!-- Mobile Menu Button -->
        <button class="md:hidden focus:outline-none" @click="handleShowHeader()">
          <svg
            class="h-6 w-6 text-slate-900"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu bg-gray-600 absolute left-0 right-0 top-20 pb-4"
        >
          <a href="#home" class="block uppercase py-2 px-4">Home</a>
          <a href="#aboutUs" class="block uppercase py-2 px-4">About Us</a>
          <router-link
            class="block uppercase py-2 px-4"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="block uppercase py-2 px-4">Gallery</a>
          <a href="#contact" class="block uppercase py-2 px-4">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn rounded-lg text-white text-sm uppercase py-2 px-4 mx-4 mt-3"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </div>
      </div>
    </header>

    <div id="home" class="carousel slide top_slider" data-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div
            class="slider-item slider_1 bg-cover bg-center h-screen"
            :style="{
              background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${dynamicContent?.content?.rsc_slider_background_image_1_link})`
            }"
          ></div>
        </div>
      </div>

      <div class="container">
        <div class="flex justify-center items-center absolute top-20 bottom-0 mx-auto w-full">
          <div
            class="content-wrapper text-center font-sans text-white wow zoomIn general_grow animated"
          >
            <p class="subtitle md:text-2xl text-xl italic">Welcome to</p>
            <h1 class="mb-4 sm:text-6xl md:text-7xl text-4xl font-bold antialiased italic">
              {{ dynamicContent?.info?.name }}
            </h1>
            <div v-if="dynamicContent?.info?.active_website_offers">
              <h2
                v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
                :key="index"
                class="my-2 lg:mb-1 md:text-xl text-lg italic"
              >
                {{ offer }}
              </h2>
            </div>
            <div class="btn-wrapper mt-6">
              <router-link
                class="btn mr-4 px-5 py-2 rounded-sm text-white md:text-xl text-base"
                :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
                >Order Now</router-link
              >
              <router-link
                v-if="dynamicContent?.info?.is_reservation_active"
                class="btn mr-2 px-5 py-2 rounded-sm text-white md:text-xl text-base"
                :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
                >Book Table</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- About us-1 Content -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_1"
      id="aboutUs"
      class="container-fluid bg-[#0d1518] py-16 about-section"
    >
      <div class="container mx-auto">
        <div class="about-content relative md:mx-0 mx-3">
          <div
            class="text-black bg-white lg:px-8 px-6 lg:py-8 py-6 content wow bounceInDown general_down animated"
          >
            <h2 class="md:text-4xl text-3xl font-bold antialiased italic mb-4 section-title">
              About us
            </h2>
            <p class="lg:text-lg text-base">
              {{ dynamicContent?.content?.rsc_about_us_text_1 }}
            </p>
          </div>
          <div
            class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 md:mx-0 mx-3 gap-2 img-wrapper"
          >
            <div
              v-if="dynamicContent?.content?.rsc_about_us_image_1_link"
              class="wow bounceInLeft general_left animated"
            >
              <img
                class="md:w-full object-cover"
                :src="dynamicContent?.content?.rsc_about_us_image_1_link"
                alt="About Image 1"
              />
            </div>
            <div
              v-if="dynamicContent?.content?.rsc_about_us_image_2_link"
              class="wow bounceInRight general_left animated"
            >
              <img
                class="md:w-full object-cover"
                :src="dynamicContent?.content?.rsc_about_us_image_2_link"
                alt="About Image 1"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About us-2 Content -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_2"
      class="container-fluid py-20 about2-section bg-gray-100 wow zoomIn general_grow animated"
    >
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div class="col-span-1 md:col-span-1 lg:col-span-1 mt-8">
            <div class="wow bounce-in-left general-left animate__animated animate__bounce-in-left">
              <p class="mb-4 lg:text-lg text-base">
                {{ dynamicContent?.content?.rsc_about_us_text_2 }}
              </p>
            </div>
          </div>
          <!-- <div class="col-span-1 md:col-span-1 lg:col-span-1">
          <img
            src="https://placehold.co/650x400"
            class="img-fluid wow bounce-in-right general-left animate__animated animate__bounce-in-right"
            alt="Responsive image"
          />
        </div> -->
        </div>
      </div>
    </section>

    <!-- Menu section start here -->
    <!-- <section id="menu" class="container-fluid md:py-20 py-12 px-3 menu-bg-img relative">
    <div class="background-overlay"></div>
    <div class="container mx-auto relative">
      <h2
        class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center text-white"
      >
        Our Menu
      </h2>
      <div class="md:grid md:grid-cols-2 gap-10">
        <div class="text-center md:mb-0 mb-5">
          <img class="w-full object-cover" src="https://placehold.co/450x300" alt="About image" />
        </div>
        <div class="flex flex-col justify-center">
          <h2 class="text-3xl font-bold antialiased italic md:mb-6 mb-4 section-title text-white">
            Main Courses
          </h2>
          <div class="mb-6">
            <div class="flex justify-between items-center md:text-xl text-lg italic text-red-400">
              <span>Pork Rillette Hand Pies</span>
              <span class="separator border border-dotted border-red-400 mx-4 flex-grow"></span>
              <span>£15</span>
            </div>
            <p class="italic text-sm text-white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div class="mb-6">
            <div class="flex justify-between items-center md:text-xl text-lg italic text-red-400">
              <span>Pork Rillette Hand Pies</span>
              <span class="separator border border-dotted border-red-400 mx-4 flex-grow"></span>
              <span>£15</span>
            </div>
            <p class="italic text-sm text-white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
          <div class="mb-6">
            <div class="flex justify-between items-center md:text-xl text-lg italic text-red-400">
              <span>Pork Rillette Hand Pies</span>
              <span class="separator border border-dotted border-red-400 mx-4 flex-grow"></span>
              <span>£15</span>
            </div>
            <p class="italic text-sm text-white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section> -->

    <!-- Gallery section start here -->
    <section
      v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
      id="gallery"
      class="container-fluid mx-auto md:py-20 py-12"
    >
      <h2
        class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center"
      >
        Gallery
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 md:mx-0 mx-3 gap-2">
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_1_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_1_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_2_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_2_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_2_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_3_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_3_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_3_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_4_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_4_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_4_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_5_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_5_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_5_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_6_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_6_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_6_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_7_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_7_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_7_text }}
            </p>
          </div>
        </div>
        <div
          v-if="dynamicContent?.content?.rsc_gallery_image_8_link"
          class="relative overflow-hidden bg-gray-300 gallery-img wow zoomIn general_grow animated"
        >
          <img
            :src="`${dynamicContent?.content?.rsc_gallery_image_8_link}`"
            alt="Gallery Image 1"
            class="object-cover w-full h-80"
          />
          <div class="absolute inset-0 flex items-center justify-center gallery-img-overlay">
            <p class="text-white font-semibold capitalize">
              {{ dynamicContent?.content?.rsc_gallery_image_8_text }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Videos section start here -->
    <section
      v-if="dynamicContent?.content?.rsc_vgallery_link_1"
      id="videos"
      class="container-fluid mx-auto md:py-20 py-12"
    >
      <div class="container mx-auto relative">
        <h2
          class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center"
        >
          Our Videos
        </h2>
        <div class="flex justify-center flex-wrap">
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_1"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_1">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_2"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_2">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_3"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_3">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_4"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_4">
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- Contact us section start here -->
    <section id="contact" class="bg-gray-100 container-fluid mx-auto md:py-18 py-12">
      <div class="container mx-auto">
        <h2
          class="md:text-4xl text-3xl font-bold antialiased italic md:mb-10 mb-8 section-title text-center"
        >
          Contact Us
        </h2>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-2/3 p-4 wow zoomIn general_grow animated">
            <ContactView :bgcolor="bgColor1"></ContactView>
          </div>

          <div class="lg:block lg:w-1/3 w-full p-4 wow zoomIn general_grow animated">
            <iframe
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              :src="mapSrc"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="dynamicContent?.content?.rsc_food_hygiene_rating > 3"
      id="allergyInfo"
      class="container-fluid rating_section py-12"
    >
      <div class="container mx-auto">
        <div class="text-center mx-auto wow zoomIn general_grow animated">
          <div>
            <img
              v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
              src="./../common/images/food-rating-3.svg"
              class="mb-4 inline-block w-1/3"
            />
            <img
              v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
              src="./../common/images/food-rating-4.svg"
              class="mb-4 inline-block w-1/3"
            />
            <img
              v-else
              src="./../common/images/food-rating-5.svg"
              class="mb-4 inline-block w-1/3"
            />
          </div>
          <div v-if="dynamicContent?.content?.rsc_allergy_text_1" class="">
            <p class="font-semibold text-xl">
              {{ dynamicContent?.content?.rsc_allergy_text_1 }}
            </p>
            <p class="font-semibold text-xl">{{ dynamicContent?.content?.rsc_allergy_text_2 }}</p>
            <a
              class="font-semibold inline-block btn mt-5 px-5 py-2 rounded-sm text-white md:text-xl text-base"
              href="https://ratings.food.gov.uk/"
              target="_blank"
              >For more details</a
            >
          </div>
        </div>
      </div>
    </section>

    <!-- Footer section start here -->
    <footer class="text-white md:pt-14 pt-12 pb-4 px-3">
      <div class="container mx-auto">
        <div class="md:grid md:grid-cols-3 gap-8">
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <img
                :src="dynamicContent?.info?.logo"
                alt="Company logo"
                class="rounded-md shadow-md mb-4"
              />
              <div class="flex space-x-4">
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_facebook !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_facebook"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_instagram !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_instagram"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
                <a
                  v-if="dynamicContent?.content?.rsc_social_media_link_twitter !== ''"
                  :href="dynamicContent?.content?.rsc_social_media_link_twitter"
                  target="_blank"
                  class="text-gray-400 hover:text-gray-200 text-3xl cursor-pointer"
                >
                  <i class="fa fa-twitter-square" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="mb-4">
              <h3 class="text-xl font-bold mb-2">Find Us</h3>
              <div class="flex flex-col space-y-2">
                <div class="flex items-center">
                  <i class="fa fa-map-marker mr-2"></i>
                  <span class="">
                    {{ dynamicContent?.info?.address }}
                  </span>
                </div>
                <div class="flex items-center">
                  <i class="fa fa-phone mr-2"></i>
                  <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="">{{
                    dynamicContent?.info?.phone_number
                  }}</a>
                </div>
                <!-- <div class="flex items-center">
                <i class="fa fa-envelope mr-2 "></i>
                <a href="mailto:bookings@gmail.com" class="">bookings@gmail.com</a>
              </div> -->
              </div>
            </div>
          </div>
          <div class="w-full md:mb-4 mb-8 wow zoomIn general_grow animated">
            <div class="flex items-center">
              <i class="fa fa-clock-o mr-2 text-xl"></i>
              <h3 class="text-xl font-bold mb-2">Opening Hours</h3>
            </div>
            <ul
              v-for="openTime in dynamicContent?.info?.opening_times"
              :key="openTime?.day_number"
              class="list-disc ml-4"
            >
              <li class="font-bold">
                {{ openTime.day_title }}:
                <span
                  class="font-normal italic"
                  v-for="(time, index) in openTime.times"
                  :key="index"
                  >{{ time }}</span
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr class="border-gray-700" />
      <div class="container mx-auto py-4">
        <div class="md:grid md:grid-cols-6 gap-8">
          <div class="col-span-2 mb-4 txt-copyRight wow zoomIn general_grow animated">
            <p class="mb-0">&copy; {{ currentYear }} - All rights reserved</p>
          </div>
          <div class="col-span-4 flex align-center justify-end wow zoomIn general_grow animated">
            <img src="./images/gulp-logo.jpg" class="md:w-20 w-12 mr-5 mt-auto" />
            <img src="./images/card-logo.jpg" class="inline-block md:w-80 w-56" />
          </div>
        </div>
      </div>
    </footer>

    <a
      id="back-to-top"
      href="#"
      class="fixed bottom-4 right-4 rounded-full p-2 bg-white shadow-md hover:bg-gray-100 hover:animate-bounce"
    >
      <i class="fa fa-chevron-up text-gray-700" aria-hidden="true"></i>
    </a>
  </div>
</template>

<style scoped>
@import '@/layouts/dynamicSite/template1/css/responsive.css';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@/layouts/dynamicSite/common/css/animate.css';
</style>
