<script setup>
let current_year = new Date().getFullYear()
const app_version = import.meta.env.VITE_APP_VERSION
</script>
<template>
  <footer class="w-full md:z-10 relative md:sticky bottom-0 bg-white border-t-2 border-gray-200">
    <div class="mx-auto overflow-hidden lg:px-8 py-3">
      <nav class="columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
        <!-- <div v-for="item in navigation.main" :key="item.name" class="pb-6">
          <a :href="item.href" class="text-sm leading-6 text-gray-600 hover:text-gray-900">{{
            item.name
          }}</a>
        </div> -->
      </nav>
      <div class="flex justify-center space-x-10">
        <!-- <a
          v-for="item in navigation.social"
          :key="item.name"
          :href="item.href"
          class="text-gray-400 hover:text-gray-500"
        >
          <span class="sr-only">{{ item.name }}</span>
          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
        </a> -->
      </div>
      <p class="text-center text-xs leading-5 text-gray-500">
        Version: {{ app_version }} &copy;{{ current_year }} OnlyGulp, Inc. All rights reserved.
      </p>
    </div>
  </footer>
</template>
